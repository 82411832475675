export const BODY_PARTS_DEFAULT_DIFFICULTY = {	
    "Quadriceps": "Easy",
    "Hamstrings": "Easy",
    "Calves": "Easy",
    "Latissimus_dorsi": "Easy",
    "Trapezius": "Easy",
    "Rhomboids": "Easy",
    "Pectoralis_major": "Easy",
    "Deltoids": "Easy",
    "Rotator_cuff_muscles": "Easy",
    "Biceps": "Easy",
    "Triceps": "Easy",
    "Rectus_abdominis": "Easy",
    "Obliques": "Easy",
    "Lower_back": "Easy",
}