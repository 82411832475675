/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:57567b8f-f171-488e-a9b5-df1a2adaad7b",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_LsP14lK7t",
    "aws_user_pools_web_client_id": "70m2cnn36nbkuin3efujgahakm",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint" : "https://5svlsqazprczhjboidlzkvhfyy.appsync-api.us-west-2.amazonaws.com/graphql"
};


export default awsmobile;
